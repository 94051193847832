import "./Dashboard.css";

import {useEffect, useState} from "react";

import IRouter from "../../router/IRouter";

import AuthButton from "../../controls/authButton/AuthButton";
import Page from "../../controls/page/Page";
import PageHeader from "../../controls/pageHeader/PageHeader";
import SphereTable from "../../controls/sphereTable/SphereTable";
import IAuthService from "../../../services/auth/IAuthService";
import ISphereService from "../../../services/sphere/ISphereService";
import {emptySphereContent, parseSphereContent, SphereContent, SphereItem} from "../../../entities/Sphere";
import EmptySpace, {VSpace} from "../../controls/emptySpace/EmptySpace";
import ProjectionTable from "../../controls/projectionTable/ProjectionTable";
import IStorage from "../../../services/storage/IStorage";

import {Node} from "../../../entities/Node";
import SphereImportForm from "../../forms/sphereImportForm/SphereImportForm";
import SphereAddForm from "../../forms/sphereAddForm/SphereAddForm";
import SphereRenameForm from "../../forms/sphereRenameForm/SphereRenameForm";
import {Route} from "../../router/routes";
import AlertForm from "../../forms/alertForm/AlertForm";
import IIterationService from "../../../services/iteration/IIterationService";

function DashboardScreen(props: IDashboardProps) {
    const makeLeave = () => {
        props.authService.leave();
        props.router.reload();
    };
    const showTariffs = () => {
        props.router.goToRoute(Route.ContentTariffs);
    };
    const showPayments = () => {
        props.router.goToRoute(Route.PaymentHistory);
    };

    const [isLoadingNow, setIsLoadingNow] = useState<boolean>(false);
    const [selectedSphereId, setSelectedSphereId] = useState<number>(-1);

    const [isAddSphereFormOpen, setIsAddSphereFormOpen] = useState(false);
    const [isImportSphereFormOpen, setIsImportSphereFormOpen] = useState(false);
    const [isRenameSphereFormOpen, setIsRenameSphereFormOpen] = useState(false);

    const [alertText, setAlertText] = useState<string>("");
    const [alertTitle, setAlertTitle] = useState<string>("");
    const [isAlertFormOpen, setIsAlertFormOpen] = useState(false);

    const addSphere = (title: string) => {
        props.sphereService.addSphere(title, JSON.stringify(emptySphereContent), (response) => {
            if (response.success) {
                updateSpheres();
            } else {
                if (response.error?.context === "tariff") {
                    setAlertTitle(response.error?.message || "Ошибка тарифа")
                    setAlertText(response.error?.description || "Ошибка тарифа")
                    setIsAlertFormOpen(true)
                    }
            }
        });
    };

    const importSphere = (title: string, content: string) => {
        props.sphereService.addSphere(title, content, (response) => {
            if (response.sphere_id) {
                updateSpheres();
            }
        });
    };

    const deleteSphere = (sphereId: number) => {
        props.sphereService.deleteSphere(sphereId, (response) => {
            if (response.success) {
                updateSpheres();
            }
        });
    };

    const openSphere = (sphereId: number) => {
        
        // операция/сценарий (переход на другой экран с предварительной очисткой кеша и загрузкой данных)

        props.router.showLoader();

        // очистка кеша (TODO: костыль, нужно разруливать через сценарии)
        props.iterationService.chargeList$.set([])
        props.iterationService.iterationList$.set([])
        props.iterationService.currentIteration$.set(null)
        props.iterationService.priorityWeightList$.set([])

        props.sphereService.getSphere(sphereId, (response) => {
            if (response.success && response.sphere) {
                const {sphere_id, title, content } = response.sphere;
                if (sphere_id && title) {
                    props.storage.setSphereName(title);

                    const sphereContent = parseSphereContent(content || "")
                    props.storage.setSphereContent(sphereContent);

                    props.storage.setSphereId(sphere_id);
                    props.iterationService.setSphereId(sphere_id)
                    props.router.showSphereEditor();
                }
            }
            props.router.hideLoader();
        });
    };

    const showRenameSphereForm = (sphereId: number) => {
        setSelectedSphereId(sphereId);
        setIsRenameSphereFormOpen(true);
    };

    const renameSphere = (title: string) => {
        props.router.showLoader();

        props.sphereService.renameSphere(
            selectedSphereId,
            title,
            (response) => {
                if (response.success) {
                    updateSpheres();
                } else {
                    props.router.hideLoader();
                }
            }
        );
    };

    const [spheres, setSpheres] = useState<SphereItem[]>([]);

    const [projections, setProjections] = useState<SphereItem[]>([]);

    const addProjection = () => {
        console.log("Add new projection");
    };

    const deleteProjection = (sphereId: number) => {};

    const editProjection = (sphereId: number) => {};

    const updateSpheres = () => {
        setIsLoadingNow(true);
        props.sphereService.getSphereList((response) => {
            setIsLoadingNow(false);
            setSpheres(response.list || []);
            setProjections([]);
        });
    };

    useEffect(() => {
        updateSpheres();
    }, []);

    return (
        <Page>
            <div className="Dashboard-container">
                <PageHeader>
                    <div />
                    <AuthButton
                        isAuth={props.authService.isSignedIn()}
                        onLeave={makeLeave}
                        onTariffs={showTariffs}
                        onPayments={showPayments}
                    />
                </PageHeader>
                <SphereTable
                    isLoadingNow={isLoadingNow}
                    spheres={spheres}
                    onDeleteSphere={deleteSphere}
                    onSelectSphere={openSphere}
                    onAddNewSphere={() => setIsAddSphereFormOpen(true)}
                    onImportSphere={() => setIsImportSphereFormOpen(true)}
                    onRenameSphere={showRenameSphereForm}
                />
                {/* <EmptySpace space={VSpace.Space16} />
                <ProjectionTable
                    isLoadingNow={isLoadingNow}
                    projections={projections}
                    onDeleteProjection={deleteProjection}
                    onEditProjection={editProjection}
                    onNewProjection={addProjection}
                /> */}
                <SphereAddForm
                    isModalOpen={isAddSphereFormOpen}
                    onClose={() => setIsAddSphereFormOpen(false)}
                    onReady={addSphere}
                />
                <SphereImportForm
                    isModalOpen={isImportSphereFormOpen}
                    onClose={() => setIsImportSphereFormOpen(false)}
                    onReady={importSphere}
                />
                <SphereRenameForm
                    isModalOpen={isRenameSphereFormOpen}
                    onClose={() => setIsRenameSphereFormOpen(false)}
                    onReady={renameSphere}
                />
                <AlertForm
                    isModalOpen={isAlertFormOpen}
                    text={alertText}
                    title={alertTitle}
                    onClose={() => setIsAlertFormOpen(false)}
                />
            </div>
        </Page>
    );
}

interface IDashboardProps {
    authService: IAuthService;
    iterationService: IIterationService;
    router: IRouter;
    storage: IStorage;
    sphereService: ISphereService;
}

export default DashboardScreen;
